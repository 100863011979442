.custom-container {
    width: 100%;
}

.ui.table {
    width: 100%;
    max-width: 100%;
    margin: 0;
}

.ui.container {
    width: 100% !important;
    max-width: 100% !important;
}